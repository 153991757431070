import i18n from 'src/i18n'

export class LogMessage {
  static dedupe = false

  static type = 'service-design::Log Message'

  static message = 'service-design::Log Message'

  // TODO: context and derived really need to be templated
  constructor(public context: any, public derived: object = {}) {}

  get dedupe(): boolean {
    return (this.constructor as any).dedupe
  }

  get entityId() {
    const values = Object.values(this.context)
    if (values.length !== 1) {
      throw new Error(
        'programmer error: entityId cannot be determined from context. ' +
          'please provide a custom entityId getter',
      )
    }
    // @ts-ignore
    return values[0].id
  }

  get id(): string {
    return `${Object.values(this.context)
      // @ts-ignore revisit when / if this becomes a generic
      .map(o => o.id)
      .join('-')}-${this.type}`
  }

  get type(): string {
    return (this.constructor as any).type
  }

  get message(): string {
    return (this.constructor as any).message
  }

  get i18nMessage(): [string, any] {
    return [this.message, { ...this.context, ...this.derived }]
  }

  get translated(): string {
    const [m, c] = this.i18nMessage
    return i18n.t(m, c)
  }
}
