import { History } from 'history'
import { formatters as jsonFormatters } from 'jsondiffpatch'
import pako from 'pako'

import { isNotNullish } from 'src/core/types'

import {
  IBackendDocumentMeta,
  IDocumentRevision,
} from 'src/core/types/document'
import api from 'src/service-design/shared/api'
import * as constants from 'src/service-design/shared/constants'
import {
  getCurrentRevision,
  getDocumentDiff,
  getSaveSelector,
} from 'src/service-design/shared/document/selectors/save'
import { retryPoller } from 'src/service-design/shared/utils/poller'

import {
  documentUpdated,
  retryFailed,
  revisionSaved,
  revisionSaveFailed,
  revisionSaveStarted,
  revisionSaving,
  revisionsReceive,
} from '.'

export const fetchLatest = (id: string | number): Promise<IDocumentRevision> =>
  api
    .get({
      url: `/documents/${id}/latest-revision`,
    })
    .then(response => response.data)

export const fetchDocuments = async (
  documentIds: number[],
): Promise<{
  documents: IDocumentRevision[]
  parentDocuments: Record<string, IDocumentRevision>
}> => {
  const firstDocumentId = documentIds[0] // All the documents should have the same parent, so just get the first

  const parentDocumentsInfo: IBackendDocumentMeta[] = (
    await api
      .get({ url: `/documents?ancestor_of=${firstDocumentId}` })
      .then(resp => resp.data)
  ).filter((document: IBackendDocumentMeta) => document.id !== firstDocumentId)

  const parentDocuments = (
    await Promise.all(parentDocumentsInfo.map(d => fetchLatest(d.id)))
  ).reduce<Record<string, IDocumentRevision>>(
    (acc, document: IDocumentRevision) => ({
      ...acc,
      [document.meta.type]: document,
    }),
    {},
  )

  const documents = await Promise.all(
    documentIds.map(documentId => fetchLatest(documentId)),
  )
  return { documents, parentDocuments }
}

export const documentsLoad = (id: string | number) => (dispatch: any) =>
  api
    .get({
      url: `/documents?ancestor_of=${id}`,
    })
    .then(response =>
      Promise.all([
        ...response.data.map((dep: IBackendDocumentMeta) =>
          fetchLatest(dep.id),
        ),
      ]),
    )
    .then(revs => dispatch(revisionsReceive(revs)))

export const documentPost = (
  name: string,
  type: string,
  data: any,
  parentId: number | null,
  version: string,
  description?: string,
) =>
  api.post({
    url: '/documents',
    json: {
      name,
      type,
      data: pako.deflate(JSON.stringify(data), { to: 'string' }),
      parent_id: parentId,
      version,
      description,
    },
  })

interface PollContext {
  dispatch: any
  resetCount: () => void
  incrementCount: () => number
}

const savePoll = async ({ dispatch, resetCount }: PollContext) => {
  await dispatch(documentSave())
  resetCount()
  return false
}

// only exported for testing
export const saveFailureCheckerFactory = ({
  dispatch,
  incrementCount,
}: PollContext) => ({
  status,
  data: { message },
}: {
  status: number
  data: { message: string }
}) => {
  const quit =
    incrementCount() >= constants.SAVE_RETRY_ATTEMPTS || status === 409

  if (quit) {
    let errorCode
    if (status === 409 || status === constants.SAVE_CONNECTION_ERROR_STATUS) {
      const matches = message.match(/\[ERR:\w+\]/)
      errorCode = matches && matches[0]
    }

    if (!errorCode) {
      errorCode = constants.SAVE_DEFAULT_ERROR
    }
    dispatch(retryFailed(errorCode))
  }
  return quit
}

export const saveDocument = () => (dispatch: any) => {
  dispatch(revisionSaveStarted())

  let failureCount = 0

  const context: PollContext = {
    dispatch,
    resetCount: () => {
      failureCount = 0
    },
    incrementCount: () => {
      failureCount += 1
      return failureCount
    },
  }

  retryPoller(
    savePoll,
    saveFailureCheckerFactory,
    constants.SAVE_INTERVAL,
    context,
  )
}

// exported for testing
export const documentSave = () => async (dispatch: any, getState: any) => {
  const state = getState()

  if (!getCurrentRevision(state)) {
    throw Error("Must call 'revisionSaved' before calling 'documentSave")
  }

  // has the document changed since we last saved? (or loaded, on first load)
  const delta = getDocumentDiff(state)
  if (!isNotNullish(delta)) {
    return Promise.resolve()
  }

  dispatch(revisionSaving())

  // hax because jsondiffpatch has awful type support
  const patch = (jsonFormatters as any).jsonpatch.format(delta)

  const document = getSaveSelector()(state)
  let response
  try {
    response = await api.post({
      url: '/revisions',
      json: {
        prev_revision_id: document.id,
        version: document.version,
        patch: pako.deflate(JSON.stringify(patch), { to: 'string' }),
      },
    })
  } catch (err) {
    dispatch(revisionSaveFailed())
    throw err
  }
  dispatch(revisionSaved(document.data))
  return dispatch(documentUpdated(response.data.meta))
}

export const documentUpdate = ({
  name = null,
  description = null,
  archived = null,
}: {
  name?: string
  description?: string
  archived?: boolean
}) => (dispatch: any, getState: any) => {
  const document = getSaveSelector()(getState())
  const updated = {
    description: description === null ? document.meta.description : description,
    name: name === null ? document.meta.name : name,
    archived: archived === null ? document.meta.archived : archived,
  }
  return api
    .put({
      url: `/documents/${document.meta.id}`,
      json: updated,
    })
    .then(() => dispatch(documentUpdated({ ...document.meta, ...updated })))
}

export const archiveDocument = (id: number) =>
  api.del({ url: `/documents/${id}` })

export const documentArchive = () => (dispatch: any, getState: any) => {
  const document = getSaveSelector()(getState())
  return archiveDocument(document.meta.id).then(() =>
    dispatch(documentUpdated({ ...document.meta, archived: true })),
  )
}

const getCopyURLPath = (id: string, history: History<any>) => {
  // TODO: this is terrible. how can we do better?
  // ie. Don't use window directly and build the new URL in a
  // more sensible way.
  const split = history.location.pathname.split('/')
  for (let i = 0; i < split.length; i += 1) {
    if (split[i].match(/^\d+$/)) {
      split[i] = id.toString()
      break
    }
  }
  return split.join('/')
}

export const documentCopy = (
  copyDetails: { name: string; parentId: number | null; copyRevision: number },
  history: History<any>,
) => (dispatch: any) =>
  api
    .post({
      url: '/documents/copy',
      json: {
        name: copyDetails.name,
        parent_id: copyDetails.parentId,
        copy_revision: copyDetails.copyRevision,
      },
    })
    .then(response => {
      history.push({
        pathname: getCopyURLPath(response.data.id, history),
        search: history.location.search,
      })
      dispatch(documentUpdated(response.data))
    })

export const goToCopiedPlan = (history: History<any>) => (response: {
  status: number
  statusText: string
  ok: boolean
  data: any
}) => {
  history.push({
    pathname: getCopyURLPath(response.data.id, history),
    search: history.location.search,
  })
}

export const goToCopiedFinderPlan = (history: History<any>) => (response: {
  status: number
  statusText: string
  ok: boolean
  data: any
}) => {
  window.location.href = `/finder/scenario/${response.data.parentId}/service-design/${response.data.id}`
}

export const documentCopyWithAncestor = (
  copyDetails: { name: string; parentName: string; copyRevision: number },
  redirect: (response: {
    status: number
    statusText: string
    ok: boolean
    data: any
  }) => void,
) => (dispatch: any) =>
  api
    .post({
      url: '/documents/copy-with-ancestor',
      json: {
        child_name: copyDetails.name,
        parent_name: copyDetails.parentName,
        copy_revision: copyDetails.copyRevision,
      },
    })
    .then(response => {
      redirect(response)
      dispatch(documentUpdated(response.data))
    })
