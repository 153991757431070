/**
 *
 * Should be installed after thunk or promise based middleware (if present) but
 * before everything else.
 *
 * Example Usage:
 * ```
 * import { createStore, applyMiddleware, compose } from 'redux'
 * import { batchMiddleware } from 'src/core/batchMiddleware'
 *
 * const batch = batchMiddleware()
 * const othermiddleware = [...]
 * const rootReducer = (state) => state
 *
 * const store = createStore(rootReducer, compose(
 *   applyMiddleware([thunk, batch.middleware, othermiddleware]),
 *   batch.enhancer,
 * ))
 *
 * ```
 */
import { Middleware, AnyAction, Dispatch } from 'redux'
import { batchedSubscribe } from 'redux-batched-subscribe'

export const TransactionClosed = {
  type: '@@batchMiddleware/transaction-closed',
} as const

const BATCH_ACTIONS = '@@batchMiddleware/batch-actions'

type BatchActions = {
  type: typeof BATCH_ACTIONS
  payload: AnyAction[]
}

export function batchActions(payload: BatchActions['payload']): BatchActions {
  return {
    type: BATCH_ACTIONS,
    payload,
  }
}

export function isBatchAction(action: AnyAction): action is BatchActions {
  return action.type === BATCH_ACTIONS
}

export function batchMiddleware<S = any>() {
  let actionsInProgress = 0
  const complete = (notify: () => void) => {
    if (actionsInProgress === 0) {
      notify()
    }
  }

  const middleware: Middleware<{}, S, Dispatch> = ({
    dispatch,
  }) => next => action => {
    let result: any
    if (isBatchAction(action)) {
      actionsInProgress += action.payload.length
      result = action.payload.map(a => {
        let res: any
        try {
          res = dispatch(a)
        } finally {
          actionsInProgress -= 1
        }
        return res
      })
    } else {
      actionsInProgress += 1
      try {
        result = next(action)
      } finally {
        actionsInProgress -= 1
      }
    }
    if (!actionsInProgress) {
      next(TransactionClosed)
    }

    return result
  }

  return {
    middleware,
    enhancer: batchedSubscribe(complete),
  } as const
}
