export const toLocalValue = (value: 'true' | 'false' | null) => {
  switch (value) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return value
  }
}
