import React from 'react'

import { ENVIRONMENT } from 'src/core/constants'
import { GlobalStyles } from 'src/core/ui/globalStyles'
import { LoadingSpinner } from 'src/service-design/shared/ui'

const EntryPointMap = {
  TR: React.lazy(() => import('./clients/tr/EntryPoint')),
  KCS: React.lazy(() => import('./clients/kcs/EntryPoint')),
  NRDemo: React.lazy(() => import('./clients/nrdemo/EntryPoint')),
  PN: React.lazy(() => import('./clients/pn/EntryPoint')),
  AZB: React.lazy(() => import('./clients/azb/EntryPoint')),
} as const

if (ENVIRONMENT !== 'prod') {
  document.title = `${document.title} [${ENVIRONMENT}]`
}

let targetEnv = process.env.REACT_APP_TARGET_ENV
if (process.env.NODE_ENV === 'development') {
  const localEnv = window.localStorage.getItem('TARGET_ENV')
  if (isValidEnv(localEnv)) {
    targetEnv = localEnv
  }
}

function isValidEnv(env: string): env is keyof typeof EntryPointMap {
  return env in EntryPointMap
}

const App: React.FC = () => {
  if (isValidEnv(targetEnv)) {
    const EntryPoint = EntryPointMap[targetEnv]
    return (
      <>
        <GlobalStyles />
        <React.Suspense fallback={<LoadingSpinner />}>
          <EntryPoint />
        </React.Suspense>
      </>
    )
  }
  throw new Error(
    `${process.env.REACT_APP_TARGET_ENV} has no EntryPoint configured`,
  )
}

export default App
