import { createAction, ActionType } from 'typesafe-actions'

import { IBackendDocumentMeta } from 'src/core/types/document'
import i18n from 'src/i18n'
import api from 'src/service-design/shared/api'
import { MODAL_ERROR } from 'src/service-design/shared/constants'
import { modalShow } from 'src/service-design/shared/modals/actions'

type ReceivedDocument = {
  data: IBackendDocumentMeta[]
  type: string
  replaceAll?: boolean
}

export const documentsLoadingByQuery = createAction('DOCUMENTS_LOADING')<
  string | undefined
>()
export const documentsLoadingByQueryFailed = createAction(
  'DOCUMENTS_LOADING_FAILED',
)<string>()
export const documentsReceiveByQuery = createAction(
  'DOCUMENTS_RECEIVE',
)<ReceivedDocument>()

export const documentsLoadingActions = {
  documentsLoadingByQuery,
  documentsLoadingByQueryFailed,
  documentsReceiveByQuery,
} as const

export type DocumentsLoadingActionType = ActionType<
  typeof documentsLoadingActions
>

export const documentsLoadByQuery = (
  {
    query,
  }: {
    query: {
      kind?: string
      // eslint-disable-next-line camelcase
      ancestor_of?: number
      // eslint-disable-next-line camelcase
      children_of?: number
      search?: string
      limit?: number
      // eslint-disable-next-line camelcase
      include_archived?: boolean
    }
  },
  replaceAll = false,
) => (dispatch: any) => {
  dispatch(documentsLoadingByQuery(query.kind))
  return api
    .get({
      url: '/documents',
      query,
    })
    .then(({ data }) =>
      dispatch(documentsReceiveByQuery({ data, type: query.kind, replaceAll })),
    )
    .catch(e => {
      dispatch(documentsLoadingByQueryFailed(query.kind))
      let message = e.toString()
      if (e.status) {
        message = `${i18n.t('Status Code')} ${e.status}: ${e.data.message}`
      }
      dispatch(
        modalShow(MODAL_ERROR, {
          header: 'There was an error loading documents from the server.',
          message,
        }),
      )
    })
}
