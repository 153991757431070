import KeycloakAPI, { KeycloakInstance } from 'keycloak-js'

import { getKeycloakConfig } from './constants'

const MIN_ACCESS_TOKEN_VALIDITY = 30
// NOTE: Keycloak uses native `Promise` if available. Type definitions are for the fallback

abstract class KeycloakBase {
  _initPromise: Promise<void>
  _keycloakApi: KeycloakInstance

  constructor(realm: string, authUrl: string) {
    this._keycloakApi = KeycloakAPI({
      clientId: 'proxy-public',
      url: authUrl,
      realm,
    })
  }

  abstract initAuth(): Promise<void>

  initKeycloak(options = {}): Promise<void> {
    this._initPromise = this._initKeycloak(options)
    return this._initPromise
  }

  async _initKeycloak(options = {}) {
    try {
      /*
        We don't use login-required here as Keycloak's updateToken error handler
        calls clearToken which then calls keycloak.login() if login-required is set
      */
      await this._keycloakApi.init({
        onLoad: 'check-sso',
        promiseType: 'native',
        ...options,
      })

      if (!this._keycloakApi.authenticated) {
        // This is needed as Keycloak JS doesn't do the login flow if 'check-sso' is used
        await this._keycloakApi.login()
      }
      console.log('Authenticated with Keycloak') // eslint-disable-line no-console
    } catch (e) {
      console.error('Problem init Keycloak', e)
      alert('Failed to login. Press OK to refresh') // eslint-disable-line no-alert
      window.location.reload()
    }
  }
}

export class ReAuthenticationController extends KeycloakBase {
  async initAuth() {
    await this.initKeycloak()
    await this.handleReAuth()
  }

  async handleReAuth() {
    await (window as any).opener.notifyReAuth({
      refreshToken: this._keycloakApi.refreshToken,
      token: this._keycloakApi.token,
      idToken: this._keycloakApi.idToken,
    })
    window.close()
  }
}

export class KeycloakController extends KeycloakBase {
  _pendingReAuthPromise: Promise<void> = null

  async initAuth() {
    await this.initKeycloak()
  }

  _handleRefreshFail() {
    if (this._pendingReAuthPromise === null) {
      this._pendingReAuthPromise = new Promise(resolve => {
        const loginUrl = this._keycloakApi.createLoginUrl()
        const authDiv = document.getElementById('auth')
        const authLink = document.getElementById('auth-link')
        authLink.setAttribute('href', loginUrl)
        authDiv.setAttribute('class', 'shown')
        ;(window as any).notifyReAuth = async ({
          refreshToken,
          token,
          idToken,
        }: {
          refreshToken: string
          token: string
          idToken: string
        }) => {
          console.log('Handling a re-auth response') // eslint-disable-line no-console
          await this.initKeycloak({
            refreshToken,
            token,
            idToken,
          })
          authDiv.setAttribute('class', '')
          resolve()
          this._pendingReAuthPromise = null
        }
      })
    }
    return this._pendingReAuthPromise
  }

  async _handleTokenExpired() {
    try {
      await this._keycloakApi.updateToken(MIN_ACCESS_TOKEN_VALIDITY)
    } catch (e) {
      // The keycloak API stupidly returns this
      if (e === undefined || e === true) {
        await this._handleRefreshFail()
        return
      }
      throw e
    }
  }

  async _waitForReady() {
    await this._initPromise
    if (this._pendingReAuthPromise) {
      await this._pendingReAuthPromise
    }
  }

  async getAuthToken(): Promise<string | null> {
    await this._waitForReady()
    if (this._keycloakApi.isTokenExpired(MIN_ACCESS_TOKEN_VALIDITY)) {
      await this._handleTokenExpired()
    }
    return this._keycloakApi.token
  }

  logout() {
    return this._keycloakApi.logout()
  }
}
const isReAuthWindow = () => {
  try {
    return window.opener && (window as any).opener.notifyReAuth
  } catch (e) {
    if (!(e instanceof DOMException)) {
      // A DOMException here can be expected, if window.opener fails with a SecurityError. Due to the tab being opened by another site
      throw e
    }
  }
  return false
}

const authEnabled = getKeycloakConfig() != null
const keycloak:
  | null
  | ReAuthenticationController
  | KeycloakController = authEnabled ? constructKeycloak() : null

export function constructKeycloak() {
  const config = getKeycloakConfig()
  if (isReAuthWindow()) {
    return new ReAuthenticationController(config.realm, config.authUrl)
  }
  return new KeycloakController(config.realm, config.authUrl)
}

export async function getAuthToken(): Promise<string | null> {
  if (keycloak instanceof KeycloakController) {
    return keycloak.getAuthToken()
  }
  return null
}
export function logout() {
  if (keycloak instanceof KeycloakController) {
    keycloak.logout()
  }
}

export async function initAuth() {
  if (keycloak) {
    await keycloak.initAuth()
  }
}
