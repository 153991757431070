import { DocumentSpec } from 'src/service-design/shared/document/types'
import { SchemaError } from 'src/service-design/shared/validation'

import { Schema } from './schema'

class MissingCollectionSchemaError extends SchemaError {
  collectionName: string
  constructor(collectionName: string) {
    super(`missing-${collectionName}`, `Missing collection: ${collectionName}`)
    this.collectionName = collectionName
  }
}
class MissingSingletonSchemaError extends SchemaError {
  singletonKey: string
  constructor(singletonKey: string) {
    super(`missing-${singletonKey}`, `Missing singleton: ${singletonKey}`)
    this.singletonKey = singletonKey
  }
}

type ImportCollectionWorksheetDefinition = {
  sheet: string
  type: 'import-collection'
  collection: string
  schema: Schema
  exclude?: boolean
  step: string // Used in the KCS importer
}

type ExportCollectionWorksheetDefinition = {
  sheet: string
  type: 'export-collection'
  schema: Schema
  selector: (state: any) => any[]
  exclude?: boolean
}

type CollectionWorksheetDefinition = {
  sheet: string
  type: 'collection'
  collection: string
  schema: Schema
  selector: (state: any) => any[]
  exclude?: boolean
}

type SingletonWorksheetDefinition = {
  sheet: string
  type: 'singleton'
  singleton: string
  schema: Schema
  exclude?: boolean
}

export const isCollectionDefinition = (
  def: WorksheetDefinition,
): def is CollectionWorksheetDefinition =>
  Boolean('collection' in def && def.collection)

export const isSingletonDefinition = (
  def: WorksheetDefinition,
): def is SingletonWorksheetDefinition =>
  Boolean('singleton' in def && def.singleton)

export type WorksheetDefinition =
  | ImportCollectionWorksheetDefinition
  | ExportCollectionWorksheetDefinition
  | CollectionWorksheetDefinition
  | SingletonWorksheetDefinition

export class WorkbookDefinition<
  DocumentKind extends string,
  ParentDocumentKind extends string
> {
  constructor(
    public sheets: WorksheetDefinition[],
    public documentSpec?: DocumentSpec<DocumentKind, ParentDocumentKind>,
  ) {}

  validate(data: { [key: string]: any; singletons?: object }) {
    const errors = []
    const includedDefs = this.sheets.filter(x => x.exclude !== true)

    const collectionDefs: CollectionWorksheetDefinition[] = []
    const singletonDefs: SingletonWorksheetDefinition[] = []

    includedDefs.forEach(x => {
      switch (x.type) {
        case 'singleton':
          singletonDefs.push(x)
          break
        case 'collection':
          collectionDefs.push(x)
          break
      }
    })

    const validateTopLevelKeys = () => {
      const expectedCollections = collectionDefs.map(x => x.collection)

      const dataKeys = Object.keys(data)
      const missingCollections = expectedCollections.filter(
        x => !dataKeys.includes(x),
      )
      const invalidCollectionTypes = Object.entries(data)
        .filter(([key]) => key !== 'singletons')
        .filter(([, value]) => !(value instanceof Array))
        .map(([key]) => key)

      const singletons = data.singletons || {}
      const expectedSingletons = singletonDefs.map(x => x.singleton)

      const singletonKeys = Object.keys(singletons)
      const missingSingletons = expectedSingletons.filter(
        x => !singletonKeys.includes(x),
      )

      const invalidSingletonTypes = Object.entries(singletons)
        .filter(([, value]) => !(value instanceof Object))
        .map(([key]) => key)

      return [
        ...missingCollections.map(x => new MissingCollectionSchemaError(x)),
        ...invalidCollectionTypes.map(x => new MissingCollectionSchemaError(x)),
        ...missingSingletons.map(x => new MissingSingletonSchemaError(x)),
        ...invalidSingletonTypes.map(x => new MissingSingletonSchemaError(x)),
      ]
    }

    const validateInstance = (instance: Record<string, any>, schema: Schema) =>
      schema.validate(instance)

    const validateCollectionInstances = () =>
      collectionDefs.flatMap(collection =>
        data[collection.collection].flatMap((x: any) =>
          validateInstance(x, collection.schema),
        ),
      )

    const validateSingletonInstances = () =>
      singletonDefs.flatMap(singletonDef =>
        validateInstance(
          // @ts-ignore 'string' can't be used to index type '{}'
          data.singletons[singletonDef.singleton],
          singletonDef.schema,
        ),
      )

    errors.push(...validateTopLevelKeys())
    if (errors.length > 0) {
      return errors
    }

    errors.push(...validateCollectionInstances())
    errors.push(...validateSingletonInstances())

    return errors
  }
}
