import { createAction, ActionType } from 'typesafe-actions'
import * as uuid from 'uuid'

interface NotificationPostPayload {
  header: string
  text: string
  kind: string
  id: string
}

export const notificationPost = createAction(
  'sd/NOTIFICATION/POST',
  (header: string, text: string, kind: string): NotificationPostPayload => ({
    header,
    text,
    kind,
    id: uuid.v4(),
  }),
)()

export const notificationDismiss = createAction(
  'sd/NOTIFICATION/DISMISS',
)<string>()

export const NotificationActions = {
  notificationPost,
  notificationDismiss,
} as const

export type NotificationActionTypes = ActionType<typeof NotificationActions>
